.services__topSubtitle-container {
  width: calc(100% - 18rem);
  height: 300px;
  margin-left: 18rem;
  overflow: hidden;
  right: 0;
  top: 0;
  font-family: "PT Sans", sans-serif;
  position: relative;
}

.topSubtitle-bg {
  position: relative;
  top: 0;
  left: 0;
  background: url("images/Background.jpeg") repeat;
  width: 100%;
  height: 300px;
  display: grid;
}

.topSubtitle-bg::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: #0000008a;
  backdrop-filter: blur(5.5px);
}

.topSubtitle-text {
  width: calc(100% - 30rem);
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  z-index: 888;
  text-align: center;
}

.topSubtitle-bg h1 {
  color: #fff;
  font-size: 5rem;
  font-weight: 100;
  top: 0;
}

.section-subtitle {
  color: #fff;
  font-size: 2rem;
  font-weight: 100;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  font-family: PT Serif, Times New Roman, serif;
}

.topSubtitle-text-container {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.topSubtitle-text-container::before {
  content: "";
  transform: translateY(-50%);
  position: absolute;
  top: 67.5%;
  width: 2.5rem;
  height: 0.1875rem;
  background: #b38865;
  margin-left: -14rem;
}
.topSubtitle-text-container::after {
  content: "";
  transform: translateY(-50%);
  position: absolute;
  top: 67.5%;
  width: 2.5rem;
  height: 0.1875rem;
  background: #b38865;
  margin-left: 0.5rem;
}

@media screen and (max-width: 1100px) {
  .topSubtitle-text {
    width: calc(100% - 9rem);
  }
}

@media screen and (max-width: 900px) {
  .services__topSubtitle-container {
    width: 100%;
    margin-left: 0rem;
  }

  .topSubtitle-text {
    width: 100%;
  }
}

@media screen and (max-width: 545px) {
  .topSubtitle-bg h1 {
    font-size: 4rem;
  }

  .section-subtitle {
    font-size: 1.5rem;
  }

  .topSubtitle-text-container::before {
    content: "";
    transform: translateY(-50%);
    position: absolute;
    top: 65%;
    background: #b38865;
    margin-left: -11.1rem;
  }
  .topSubtitle-text-container::after {
    content: "";
    transform: translateY(-50%);
    position: absolute;
    top: 65%;
    background: #b38865;
    margin-left: 0.4rem;
  }
}

@media screen and (max-width: 545px) {
  .topSubtitle-bg h1 {
    font-size: 3.5rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }

  .topSubtitle-text-container::before {
    content: "";
    transform: translateY(-50%);
    position: absolute;
    width: 2rem;
    top: 64.5%;
    background: #b38865;
    margin-left: -7.9rem;
  }
  .topSubtitle-text-container::after {
    content: "";
    transform: translateY(-50%);
    position: absolute;
    top: 64.5%;
    width: 2rem;
    background: #b38865;
    margin-left: 0.4rem;
  }

  @media screen and (max-width: 430px) {
    .topSubtitle-text-container::before {
      content: "";
      transform: translateY(-50%);
      position: absolute;
      top: 61.5%;
    }
    .topSubtitle-text-container::after {
      content: "";
      transform: translateY(-50%);
      position: absolute;
      top: 61.5%;
    }
    .topSubtitle-bg {
      background-position: 70rem;
    }
  }

  @media screen and (max-width: 380px) {
    .topSubtitle-bg h1 {
      font-size: 3.3rem;
    }

    .topSubtitle-text-container::before {
      content: "";
      transform: translateY(-50%);
      position: absolute;
      top: 61.5%;
    }
    .topSubtitle-text-container::after {
      content: "";
      transform: translateY(-50%);
      position: absolute;
      top: 61.5%;
    }
  }

  @media screen and (max-width: 320px) {
    .topSubtitle-bg h1 {
      font-size: 2.9rem;
    }
  }
}
