.services__header-container {
  margin-top: 6rem;
  margin-left: 20rem;
  white-space: pre-wrap;
}

.services__header-text {
  text-align: center;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
  padding-top: 6rem;
  box-sizing: border-box;
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
}

.services__header-text h1 {
  font-size: 2.2rem;
  line-height: 1.33333;
  padding-bottom: 2rem;
  font-weight: 330;
  color: #393939;
}

.services__header-container p1:nth-child(2) {
  font-size: 1rem;
  width: fit-content;
  color: #8a8888;
  font-weight: 100;
}

.services__header-container p1:nth-child(3) {
  font-size: 1.1rem;
  color: #8a8888;
  font-weight: 100;
  line-height: 4;
}

.services__header-image-grid {
  display: grid;
  grid-template-areas:
    "section__header-machine"
    "section__header-mannequin"
    "section__header-zipper"
    "section__header-thimble"
    "section__header-scissor"
    "section__header-ruler";
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
  grid-template-columns: repeat(6, minmax(auto, auto));
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.services__header-image-grid img {
  /* border: 1px solid blue; */
  width: 75%;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 900px) {
  .services__header-container {
    margin-left: 0rem;
  }
  .services__top-container {
    width: 100%;
  }
}
