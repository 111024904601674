.contactMe__body-container {
  margin-top: 4rem;

  margin-left: auto;
  white-space: pre-wrap;
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  /* VERY IMPORTANT*/
  width: calc(100% - 30rem);
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr;
}

.contactMe__body-logo {
  margin: auto;

  width: 75%;
  height: 70%;
  min-width: 50%;
  margin-bottom: -13rem;
}

.contactMe__body-grid {
  grid-template-areas: "contactMe__body-logo", "contactMe__body-subgrid",
    "contactMe__body-info", "contactMe__body-map", "contactMe__body-footer";
}

.contactMe__body-subgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-left: auto;
  margin-right: auto;
}

label[for="name"],
label[for="email"],
label[for="message"] {
  color: #667;
  font-weight: 500;
}

label[for="address"],
label[for="number"],
label[for="email"],
label[for="facebook"] {
  color: #667;
  padding-top: 0.3rem;
}

text[for="address"],
text[for="number"],
text[for="email"],
text[for="facebook"] {
  color: #667;
  font-weight: 650;
  padding-bottom: 3.2rem;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

textarea {
  height: 10rem;
}

.container1 {
  border-radius: 5px;
  padding: 20px;
}

.contactMe__body-info {
  display: block;
  max-width: fit-content;
}
.container2 {
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-areas: "container2-grid-address", "container2-grid-number",
    "container2-grid-email", "container2-grid-facebook";
}

.contactMe__body-map {
  max: 100%;
}

.contactMe__body-google-maps {
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: auto;
  width: calc(105% - 12rem);
  border: none;
}

.contactMe__body-footer {
  text-align: center;
  margin-right: 10rem;
}

.contactMe__body-footer p1 {
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 2.5rem;
  padding-bottom: 3rem;
}

@media screen and (max-width: 1050px) {
  .contactMe__body-container {
    margin-left: 20rem;
    width: 67%;
  }

  .contactMe__body-subgrid {
    gap: 1rem;
  }

  .container2,
  .container1 {
    width: 90%;
  }

  .contactMe__body-logo {
    width: 90%;
  }

  .contactMe__body-google-maps {
    width: calc(110% - 6rem);
  }
}

@media screen and (max-width: 1020px) {
  .contactMe__body-container {
    width: 65%;
    margin-left: 20rem;
    margin-right: auto;
  }
  .contactMe__body-footer {
    margin-left: 6rem;
  }
  .contactMe__body-logo {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 900px) {
  .contactMe__body-container {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 550px) {
  .contactMe__body-container {
    width: 92%;
    margin-left: 2.5rem;
    margin-top: 10rem;
  }
  .contactMe__body-logo {
    margin-bottom: -20rem;
    width: 90%;
    height: 50%;
  }
  .contactMe__body-subgrid {
    display: grid;
    grid-template-columns: 1fr;
  }
  .contactMe__body-info {
    background-color: rgb(250, 246, 246);
    margin-left: 1rem;
    width: 80%;
  }
  .container2 {
  }
}

/* IPHONE 12 MAX SCREEN */
@media screen and (max-width: 430px) {
  .contactMe__body-container {
    width: 90%;
    margin-left: 2rem;
    /* margin-top: 40rem; */
  }
  .contactMe__body-logo {
    margin-top: -18rem;
    width: 90%;
    height: 50%;
  }
  .contactMe__body-subgrid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 42rem;
  }
  .contactMe__body-info {
    background-color: rgb(250, 246, 246);
    margin-left: 1rem;
    width: 80%;
  }
  .contactMe__body-google-maps {
    width: calc(100% - 1.3rem);
  }

  .contactMe__body-footer {
    width: 80%;
    margin-left: 1rem;
  }
}
@media screen and (max-width: 415px) {
  .contactMe__body-logo {
    margin-top: -15rem;
    width: 90%;
    height: 40%;
  }
  .contactMe__body-subgrid {
    margin-top: 13rem;
  }

  .contactMe__body-google-maps {
    padding-right: 3rem;
  }
}

@media screen and (max-width: 380px) {
  .contactMe__body-container {
    margin-left: 2rem;
    width: 90%;
  }

  .contactMe__body-google-maps {
    margin-left: 1rem;
  }

  .contactMe__body-footer {
    width: 80%;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 330px) {
  .contactMe__body-container {
    margin-left: 2rem;
  }
  .contactMe__body-grid {
    width: 90%;
  }
}
