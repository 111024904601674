.aboutMe__body-container {
  margin-top: 6rem;

  margin-left: auto;
  white-space: pre-wrap;
  font-family: proxima-nova, Helvetica, Arial, sans-serif;
  /* VERY IMPORTANT*/
  width: calc(100% - 30rem);
  padding-bottom: 3rem;
}

.aboutMe__body-grid {
  margin-left: 3rem;
  width: 85%;
}

.aboutMe-table {
  margin-left: auto;
  margin-right: auto;
}

.aboutMe__body-aboutMe {
  display: grid;
  grid-template-areas:
    "aboutMe__body-subject"
    "aboutMe__body-text-aboutMe";
  grid-template-columns: 0.5fr 1fr;
  width: 90%;
  padding-bottom: 10rem;
}

.aboutMe__body-article {
  margin-top: 5rem;
  display: grid;
  grid-template-areas:
    "aboutMe__body-article-subject"
    "aboutMe__body-article-text";
  grid-template-columns: 0.5fr 1fr;
  width: 90%;
}

.aboutMe__body-hobby {
  /* margin-top: 3rem; */
  display: grid;
  grid-template-areas:
    "aboutMe__body-hobby-subject"
    "aboutMe__body-hobby-text";
  grid-template-columns: 0.5fr 1fr;
  width: 90%;
}

.aboutMe__body-line-divider {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 90%;
}

.aboutMe__body-aboutMe-text {
  color: #665;
}

.aboutMe__body-grid h1 {
  font-size: 1.5rem;
  font-weight: 430;
  color: #393939;
}

.aboutMe__body-subtitle {
  color: #caac93;
  font-family: "pt-serif", Times New Roman, serif;
  font-style: italic;
}

.aboutMe__body-article-subject p1 {
  color: #caac93;
  font-family: "pt-serif", Times New Roman, serif;
  font-style: italic;
}
.aboutMe__body-subtitle2 {
  color: #caac93;
  font-family: "pt-serif", Times New Roman, serif;
  font-style: italic;
  font-size: larger;
}

.slider {
  position: relative;
  height: 6rem;
  width: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13rem;
  margin-left: auto;
  margin-right: auto;
}

.aboutMe__body-aboutMe-img {
  margin-top: 0.2rem;
  position: relative;
  width: 100%;
  border-radius: 10px;
  z-index: 10;
  user-select: none;
}

.right-arrow {
  position: absolute;
  margin-right: -32rem;
  font-size: 250%;
  color: #000;
  z-index: 11;
  cursor: pointer;
}

.left-arrow {
  position: absolute;
  margin-right: 32rem;
  font-size: 250%;
  color: #000;
  z-index: 11;
  cursor: pointer;
}

.sliderJazz {
  position: relative;
  height: 6rem;
  width: 28rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13rem;
  margin-left: auto;
  margin-right: auto;
}

.aboutMe__body-hobby-img {
  margin-top: -2rem;
  margin-left: 0.3rem;
  position: relative;
  width: 100%;
  border-radius: 10px;
  z-index: 10;
  user-select: none;
}

.right-arrowJazz {
  position: absolute;
  margin-right: -32rem;
  font-size: 250%;
  color: #000;
  z-index: 11;
  cursor: pointer;
}

.left-arrowJazz {
  position: absolute;
  margin-right: 32rem;
  font-size: 250%;
  color: #000;
  z-index: 11;
  cursor: pointer;
}

.aboutMe__body-text-link {
  color: #caac93;
  text-decoration: none;
}

.aboutMe__body-text {
  width: 2rem;
}

@media screen and (max-width: 1200px) {
  .aboutMe__body-container {
    margin-left: 25rem;
  }

  .aboutMe__body-grid {
    width: 95%;
  }
}

@media screen and (max-width: 1100px) {
  .aboutMe__body-container {
    margin-left: 20rem;
  }

  .aboutMe__body-grid {
    width: 100%;
  }

  .aboutMe__body-aboutMe {
    gap: 0.5rem;
  }
  .aboutMe__body-article {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 900px) {
  .aboutMe__body-container {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .aboutMe__body-grid {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 660px) {
  .aboutMe__body-hobby {
    grid-template-columns: 1fr;
  }

  .slider {
    margin-left: -4rem;
  }
}

@media screen and (max-width: 559px) {
  .slider {
    height: 4rem;
    width: 20rem;
  }

  .right-arrow {
    position: absolute;
    margin-right: -25rem;
  }

  .left-arrow {
    position: absolute;
    margin-right: 25rem;
  }
  .aboutMe__body-aboutMe {
    padding-bottom: 12rem;
  }

  .aboutMe__body-aboutMe-img {
    margin-top: 2rem;
  }

  .sliderJazz {
    height: 4rem;
    width: 20rem;
  }

  .right-arrowJazz {
    position: absolute;
    margin-right: -25rem;
  }

  .left-arrowJazz {
    position: absolute;
    margin-right: 25rem;
  }
  .aboutMe__body-hobby {
    padding-bottom: 12rem;
  }

  .aboutMe__body-hobby-img {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 430px) {
  .aboutMe__body-hobby-subject {
    /* margin-bottom: -4.5rem; */
  }
  .aboutMe__body-text-aboutMe {
    width: 120%;
  }

  .right-arrow {
    position: absolute;
    left: 20.2rem;
  }

  .left-arrow {
    position: absolute;
    left: -2.7rem;
  }

  .slider {
    left: -0.2rem;
    margin-right: -1.8rem;
  }

  .right-arrowJazz {
    position: absolute;
    left: 20.5rem;
  }

  .left-arrowJazz {
    position: absolute;
    left: -2.4rem;
  }

  .sliderJazz {
    margin-top: 5rem;
    margin-right: 2rem;
    left: -0.2rem;
  }
}

@media screen and (max-width: 380px) {
  .aboutMe__body-aboutMe {
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .aboutMe__body-subject {
    width: 80%;
    text-align: center;
  }

  .aboutMe__body-text-aboutMe {
    width: 90%;
  }

  .slider {
    margin-left: 2rem;
    width: 100%;
  }

  .right-arrow {
    top: 25%;
    left: 14.2rem;
  }

  .left-arrow {
    top: 1rem;
    left: -4.5rem;
  }

  .aboutMe__body-aboutMe-img {
    margin-left: -4rem;
  }

  .sliderJazz {
    top: 6rem;
    margin-left: -1.3rem;
    padding-left: 2.5rem;
    width: 105%;
  }

  .right-arrowJazz {
    top: -25%;
    left: 19.3rem;
  }

  .left-arrowJazz {
    top: -25%;
    margin-left: 2rem;
  }

  .aboutMe__body-article {
    grid-template-columns: 1fr;
  }

  .aboutMe__body-hobby-img {
    margin-top: -3rem;
    margin-left: -0.2rem;
  }
}

@media screen and (max-width: 320px) {
  .sliderJazz {
    margin-left: 0rem;
    width: 95%;
  }

  .right-arrowJazz {
    top: -0.4rem;
    left: 15.5rem;
  }

  .left-arrowJazz {
    top: -0.4rem;
    margin-right: 17rem;
  }

  .slider {
    margin-left: 2.5rem;
    width: 95%;
  }

  .right-arrow {
    /* position: fixed; */
    top: 1rem;

    left: 12rem;
  }

  .left-arrow {
    top: 1rem;

    left: -4.7rem;
  }
}
